export const examplesData = [
    {
        key: 'AJ Produkter - Office',
        link: 'aj-office',
        customer: 'AJ Produkter',
        industry: 'Furniture',
        media: 'image',
        title: 'The Open Office',
        thumbnail: 'https://res.cloudinary.com/qiwio/image/upload/q_auto,f_auto/v1666687467/Website%20Examples/office_w52ryb.jpg',
        contentUrl: 'https://player.qiwio.io/image/WN5vr4x36xw?lang=se&mode=embed',
        contentUrlMobile: 'https://player.qiwio.io/image/dXY1Z9js_T0?lang=se&mode=embed',
        description: 'AJ Produkter sell over 15 000 products. Now they make it easier than ever before for their customers to be inspired online and find what they need by making their product content, shoppable.',
        stat: [
            {
                number: '70%',
                statDescription: 'of visitors engaged with interactive content'
            },
            {
                number: '42%',
                statDescription: 'of visitors landed on product pages'
            },
        ]
    },
    {
        key: 'AJ Produkter - Industry',
        link: 'aj-industry',
        customer: 'AJ Produkter',
        industry: 'Furniture',
        media: 'image',
        title: 'A better warehouse and workshop environment',
        thumbnail: 'https://res.cloudinary.com/qiwio/image/upload/q_auto,f_auto/v1666687599/Website%20Examples/industry_d2l34a.jpg',
        contentUrl: 'https://player.qiwio.io/image/eH-beoYDbeo?lang=se&mode=embed',
        contentUrlMobile: 'https://player.qiwio.io/image/tEmig35CDSs?lang=se&mode=embed',
        description: 'AJ Produkter sell over 15 000 products. Now they make it easier than ever before for their customers to be inspired online and find what they need by making their product content, shoppable.',
        stat: [
            {
                number: '70%',
                statDescription: 'of visitors engaged with interactive content'
            },
            {
                number: '42%',
                statDescription: 'of visitors landed on product pages'
            },
        ]
    },
    {
        key: 'AJ Produkter - School',
        link: 'aj-school',
        customer: 'AJ Produkter',
        industry: 'Furniture',
        media: 'image',
        title: 'A better school environment',
        thumbnail: 'https://res.cloudinary.com/qiwio/image/upload/q_auto,f_auto/v1666687670/Website%20Examples/school_vu839p.jpg',
        contentUrl: 'https://player.qiwio.io/image/5HNKMH3hzA4?lang=se&mode=embed',
        contentUrlMobile: 'https://player.qiwio.io/image/g5w-Q5GnBAA?lang=se&mode=embed',
        description: 'AJ Produkter sell over 15 000 products. Now they make it easier than ever before for their customers to be inspired online and find what they need by making their product content, shoppable.',
        stat: [
            {
                number: '70%',
                statDescription: 'of visitors engaged with interactive content'
            },
            {
                number: '42%',
                statDescription: 'of visitors landed on product pages'
            },
        ]
    },
    {
        key: 'Tenson - Himalaya Jacket Mens',
        link: 'tenson-jacket-mens',
        customer: 'Tenson',
        industry: 'Fashion',
        media: 'video',
        title: 'Himalaya Shell Jacket Mens',
        thumbnail: 'https://res.cloudinary.com/qiwio/image/upload/q_auto,f_auto/v1666687945/Website%20Examples/tenson-shell-m_ald7va.png',
        contentUrl: 'https://player.qiwio.io/gzTtV4sqeUCdOQ_WOYWB_w?mode=embed',
        description: "Tenson turned their 'passive' product content into engaging, interactive shopping experiences which raised engagement, time on site and conversions.",
        stat: [
            {
                number: '70%',
                statDescription: 'of visitors engaged with interactive content'
            },
            {
                number: '+56%',
                statDescription: 'increase in video completion rates'
            },
            {
                number: '50%',
                statDescription: 'of visitors landed on product pages'
            },
        ]
    },
    {
        key: 'Tenson - Himalaya Jacket Womens',
        link: 'tenson-jacket-womens',
        customer: 'Tenson',
        industry: 'Fashion',
        media: 'video',
        title: 'Himalaya Shell Jacket Womens',
        thumbnail: 'https://res.cloudinary.com/qiwio/image/upload/q_auto,f_auto/v1666688073/Website%20Examples/tenson-shell-w_iwver2.png',
        contentUrl: 'https://player.qiwio.io/mEOaeVpULnBwxKF6czp9sg?mode=embed',
        description: "Tenson turned their 'passive' product content into engaging, interactive shopping experiences which raised engagement, time on site and conversions.",
        stat: [
            {
                number: '70%',
                statDescription: 'of visitors engaged with interactive content'
            },
            {
                number: '+56%',
                statDescription: 'increase in video completion rates'
            },
            {
                number: '50%',
                statDescription: 'of visitors landed on product pages'
            },
        ]
    },
    {
        key: 'Tenson - TXlite Mens',
        link: 'tenson-txlite',
        customer: 'Tenson',
        industry: 'Fashion',
        media: 'video',
        title: 'TXlite Down Jacket Mens',
        thumbnail: 'https://res.cloudinary.com/qiwio/image/upload/q_auto,f_auto/v1666688233/Website%20Examples/tenson-txlite_q7hjv7.png',
        contentUrl: 'https://player.qiwio.io/TJ2TOprahmD5Fzgq0tJZ0g?mode=embed',
        description: "Tenson turned their 'passive' product content into engaging, interactive shopping experiences which raised engagement, time on site and conversions.",
        stat: [
            {
                number: '70%',
                statDescription: 'of visitors engaged with interactive content'
            },
            {
                number: '+56%',
                statDescription: 'increase in video completion rates'
            },
            {
                number: '50%',
                statDescription: 'of visitors landed on product pages'
            },
        ]
    },
    {
        key: 'Tenson - Himalaya Teddy Fleece',
        link: 'tenson-teddy',
        customer: 'Tenson',
        industry: 'Fashion',
        media: 'video',
        title: 'Himalaya Teddy Fleece',
        thumbnail: 'https://res.cloudinary.com/qiwio/image/upload/q_auto,f_auto/v1666688387/Website%20Examples/tenson-teddy_vss84f.png',
        contentUrl: 'https://player.qiwio.io/Ta_0VCacNbJLaTgXet-NSg?mode=embed',
        description: "Tenson turned their 'passive' product content into engaging, interactive shopping experiences which raised engagement, time on site and conversions.",
        stat: [
            {
                number: '70%',
                statDescription: 'of visitors engaged with interactive content'
            },
            {
                number: '+56%',
                statDescription: 'increase in video completion rates'
            },
            {
                number: '50%',
                statDescription: 'of visitors landed on product pages'
            },
        ]
    },
    {
        key: 'Tenson - Hurricane Rain Jacket',
        link: 'tenson-hurricane',
        customer: 'Tenson',
        industry: 'Fashion',
        media: 'video',
        title: 'Biscaya & Hurrican Rain Jacket',
        thumbnail: 'https://res.cloudinary.com/qiwio/image/upload/q_auto,f_auto/v1666688596/Website%20Examples/tenson-hurricane_ixzbf7.png',
        contentUrl: 'https://player.qiwio.io/ELJm9dhmQ4Jd0UtRPijjDw?mode=embed',
        description: "Tenson turned their 'passive' product content into engaging, interactive shopping experiences which raised engagement, time on site and conversions.",
        stat: [
            {
                number: '70%',
                statDescription: 'of visitors engaged with interactive content'
            },
            {
                number: '+56%',
                statDescription: 'increase in video completion rates'
            },
            {
                number: '50%',
                statDescription: 'of visitors landed on product pages'
            },
        ]
    },
]
{/*
    {
        key: 'blugiallo-flannel-suit',
        customer: 'Blugiallo',
        industry: 'Fashion',
        media: 'image',
        title: 'Flannel Suit',
        thumbnail: 'https://images.unsplash.com/photo-1666269878905-29f85729268b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80',
        contentUrl: 'https://player.qiwio.io/aD2Y6mD0XheG3AVvV2HPxg',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        stat: [
            {
                number: '48%',
                statDescription: 'Greater interaction rate'
            },
            {
                number: '48%',
                statDescription: 'Greater interaction rate'
            },
            {
                number: '48%',
                statDescription: 'Greater interaction rate'
            },
        ]
    },
*/}